
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { deleteTag, getTagGroupList, getTagList, setTag } from "@/api/request/tag";

//组件
@Component({
  name: "TagLibrary",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //标签列表
  private groupList: any[] = []; //标签组列表
  private listLoading: boolean = false; //等待显示
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    label: "", //标签
    remark: "", //备注
    id: undefined, //标签ID
    group_name: "", //标签组
    relation_id: 0, //标签组ID
  };

  //创建
  created() {
    //获取标签组
    this.getGroupList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagList();

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //添加标签
  private handleAdd(): void {
    //初始化数据
    this.form = {
      label: "", //标签
      remark: "", //备注
      id: undefined, //标签ID
      group_name: "", //标签组
      relation_id: 0, //标签组ID
    };

    //显示界面
    this.dialogShow = true;
  }

  //创建标签
  private async createData() {
    //异常处理
    if (!this.checkName()) return;
    if (!this.checkGroup()) return;
    if (!this.checkRemark()) return;

    //显示等待
    this.dialogLoading = true;

    //创建标签
    await setTag(this.form);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //更新标签
  private async updateData() {
    //异常处理
    if (!this.checkName()) return;
    if (!this.checkGroup()) return;
    if (!this.checkRemark()) return;

    //显示等待
    this.dialogLoading = true;

    //创建标签
    await setTag(this.form);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //检测名称
  private checkName(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.form.label)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入标签名称！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //获取标签组
  private async getGroupList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagGroupList();

    //数据赋值
    this.groupList = data;
    this.groupList.unshift({ id: 0, name: "请选择标签组" });
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id === undefined;
  }

  //标签组
  private checkGroup(): boolean {
    //判断异常
    if (this.form.relation_id == 0) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请选择标签组！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //检测备注
  private checkRemark(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.form.remark)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入标签备注！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //编辑标签
  private handleEdit(data: any): void {
    //数据赋值
    this.form = data;

    //显示界面
    this.dialogShow = true;
  }

  //删除标签
  private handleDelete(data: any): void {
    //显示提示
    MessageBox.confirm("确定删除《" + data.label + "》标签？", "提示", {
      type: "warning",
      cancelButtonText: "取消",
      confirmButtonText: "确定",
      dangerouslyUseHTMLString: true,
    })
      .then(async () => {
        //删除标签
        await deleteTag({ id: data.id });

        //显示提示
        this.$message.success("操作成功");

        //获取数据
        this.getList();
      })
      .catch((action) => {});
  }
}
